import React, { useState } from 'react';
import { Box, Stack, Button, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import InputField from '@gaia/components/InputField';

const schema = yup.object({
    name: yup.string().default(''),
    email: yup
        .string()
        .required()
        .email()
        .default(''),
    message: yup
        .string()
        .required()
        .default(''),
    forBots: yup.string().default(''),
});

type FormData = yup.InferType<typeof schema>;

const ContactForm = () => {
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');

    const { control, register, handleSubmit, reset } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.getDefault(),
    });

    const { t } = useTranslation();

    const handleSubmission = async (formData: FormData) => {
        try {
            setFormError('');
            setFormSuccess('');
            const response = await fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    ...formData,
                    'form-name': 'contact-us',
                }).toString(),
            });

            if (response.ok) {
                reset();
                setFormSuccess(
                    'Thanks for getting in touch! We will get back to you soon. Keep an eye on your spam folder as we sometimes land there.'
                );
            } else {
                throw new Error(response.statusText);
            }
        } catch (e) {
            const error = e as Error;
            console.log('Failed to submit form.', error.message);
            setFormError('There was a problem submitting your form. Please try again.');
        }
    };

    return (
        <form
            name="contact-us"
            data-netlify="true"
            netlify-honeypot="forBots"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleSubmission)}
        >
            <Stack
                sx={{
                    gap: { xs: 2, sm: 3 },
                    mb: { xs: 4, sm: 5 },
                }}
            >
                <input type="hidden" name="form-name" value="contact-us" />
                <div hidden>
                    <label>
                        <input {...register('forBots')} />
                    </label>
                </div>
                <InputField
                    name="name"
                    label={t('contactForm_inputLabel_name') ?? 'Name'}
                    control={control}
                    schema={schema}
                />
                <InputField
                    name="email"
                    label={t('contactForm_inputLabel_yourEmail') ?? 'Email address'}
                    control={control}
                    schema={schema}
                />
                <InputField
                    name="message"
                    label={t('contactForm_inputLabel_message') ?? 'Message'}
                    control={control}
                    schema={schema}
                    multiline
                    rows={4}
                />
            </Stack>
            <Alert
                variant="filled"
                severity="error"
                sx={{ display: formError.length > 0 ? 'flex' : 'none' }}
            >
                {formError}
            </Alert>
            <Alert
                variant="filled"
                severity="success"
                sx={{ display: formSuccess.length > 0 ? 'flex' : 'none' }}
            >
                {formSuccess}
            </Alert>
            <Box display="flex" sx={{ mt: { xs: 4, sm: 5 } }}>
                <Button variant="contained" type="submit" sx={{ width: { xs: '100%', sm: 240 } }}>
                    <Trans i18nKey="contactForm_button_send">Send Message</Trans>
                </Button>
            </Box>
        </form>
    );
};

export default ContactForm;
